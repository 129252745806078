@keyframes expand-right {
  from {
    width: 5vw;
  }
  to {
    width: 35vw;
  }
}

@keyframes fade-out {
  to {
    opacity: 0;
    z-index: -1;
  }
}

.fade-out {
  opacity: 1;
  animation: fade-out 1s forwards;
}

#splash-screen {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(
    circle farthest-corner at 75% 50%,
    #c3d3e5,
    #7d9aaa 15%,
    #485463 35%,
    #03202f 60%
  );
  z-index: 100;
  overflow: hidden;
}

.splash-start-button {
  display: flex;
  border: transparent solid 1px;
  background-color: #d52b1e;
  color: white;
  position: relative;
  width: 18vw;
  height: 7vh;
  align-items: center;
  justify-content: center;
  font-size: 2vw;
  border-radius: 5px;
}

.splash-start-button:hover {
  cursor: pointer;
  border: #d52b1e solid 1px;
  background-color: white;
  color: #d52b1e;
}

.splash-text-container {
  padding: 10vw 10vh;
  position: relative;
  width: 50vw;
  height: auto;
}

.splash-text-container .splash-title h1 {
  position: relative;
  width: 100%;
  height: auto;
  font-family: Antenna;
  font-style: normal;
  font-weight: 400;
  font-size: 9vh;
  text-align: left;
  color: #fff;
  margin: 0;
}

.splash-text-container .splash-text h3 {
  position: relative;
  line-height: 1.5;
  width: 35vw;
  font-family: Antenna;
  font-style: normal;
  font-weight: 400;
  font-size: 3vh;
  display: flex;
  align-items: center;
  text-align: left;
  color: #fff;
}

.splash-image-container {
  height: 100vh;
  width: 50vw;
}

.splash-image {
  position: absolute;
  width: 75vw;
  height: auto;
  left: 35vw;
  top: 0;
}

.splash-text-container hr {
  background-color: #d52b1e;
  border: none;
  height: 1vh;
  width: 35vw;
  animation: expand-right 5s backwards;
  margin: 0;
}
.intro-screen {
  display: flex;
  position: fixed;
  flex-flow: column;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: space-evenly;
}

.intro-text {
  padding:0 5%;
}

.intro-text h1 {
  font-size: 4.5vh;
}

.intro-text h2 {
  font-size: 3vh;
  font-weight: 400;
  line-height: 1.5;
}

.topic-section-container {
  display: flex;
  flex-direction: row;
  width: 75vw;
  justify-content: center;
}

.topic-image {
  height: 22vw;
  width: 22vw;
  object-fit: cover;
}

.topic-option-container {
  cursor: pointer;
  display: flex;
  flex-flow: column;
  align-items: center;
  margin-left:1%;
  margin-right: 1%;
}

.topic-button {
  display: flex;
  align-items: center;
  width: 40vh;
  height: 4vw;
  justify-content: center;
  background-color: #d52b1e;
  color: white;
  border-radius: 5px;
  border: transparent solid 1px;
  margin-top: 10%;
}

.topic-button h3 {
  font-weight: 400;
  font-size: 4vh;
}

.topic-option-container:hover > .topic-button {
  border: #d52b1e solid 1px;
  background-color: white;
  color: #d52b1e;
}

.topic-option-container :hover {
  cursor: pointer;
}

.carousel {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.carousel-container {
  overflow: hidden;
  width: 75vw;
}

.partners-carousel {
  width: 60vw;
}

.inner {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.carousel-item {
  cursor: pointer;
  width: 9.5vw;
  height: 9.5vw;
  border-radius: 50%;
  margin: 1.5vw;
    border: solid black 4px;
overflow: hidden;
}

.carousel-item img {
  width: auto;
  height: 100%;
  background-color: white;
  filter: grayscale(100%);
}

.carousel-buffer {
  justify-self: stretch;
  height: auto;
}

.carousel-item.active {
  width: 12.5vw;
  height: 12.5vw;
  border: solid #d52b1e 4px;
  margin: 0;
  transition: all 0.5s;
}

.carousel-item.active img {
  filter: grayscale(0%);
}

.carousel-item:hover img {
  filter: grayscale(0%);
  transition: all 0.25s;
}
